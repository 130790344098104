/* eslint-disable */
import React from 'react'
import { graphql, navigate } from 'gatsby'
import StateDropdown from '@leshen/gatsby-theme-leshen/src/components/StateDropdown'

import coupleAtBeach from '../../images/heroes/couple-walking-on-beach.jpg'
import manHoldingTablet from '../../images/people/holding-tablet.jpg'

import {
  Hero,
  Link,
  LinkButton,
  SplitContent,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../../components/FooterPPC'
import header from '../../components/HeaderPPC'
import DynamicCTACard from '../../components/DynamicCTACard'
import SeoCtaBar from '../../components/SeoCtaBar'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()
  const brandyList = ['disclaimer-aet-lp-bingplans']

  const stateDropdownData = [
    { label: 'Alabama', value: '/locations/alabama' },
    { label: 'Alaska', value: '/locations/alaska' },
    { label: 'Arizona', value: '/locations/arizona' },
    { label: 'Arkansas', value: '/locations/arkansas' },
    { label: 'California', value: '/locations/california' },
    { label: 'Colorado', value: '/locations/colorado' },
    { label: 'Connecticut', value: '/locations/connecticut' },
    { label: 'Delaware', value: '/locations/delaware' },
    { label: 'Florida', value: '/locations/florida' },
    { label: 'Georgia', value: '/locations/georgia' },
    { label: 'Hawaii', value: '/locations/hawaii' },
    { label: 'Idaho', value: '/locations/idaho' },
    { label: 'Illinois', value: '/locations/illinois' },
    { label: 'Indiana', value: '/locations/indiana' },
    { label: 'Iowa', value: '/locations/iowa' },
    { label: 'Kansas', value: '/locations/kansas' },
    { label: 'Kentucky', value: '/locations/kentucky' },
    { label: 'Louisiana', value: '/locations/louisiana' },
    { label: 'Maine', value: '/locations/maine' },
    { label: 'Maryland', value: '/locations/maryland' },
    { label: 'Massachusetts', value: '/locations/massachusetts' },
    { label: 'Michigan', value: '/locations/michigan' },
    { label: 'Minnesota', value: '/locations/minnesota' },
    { label: 'Mississippi', value: '/locations/mississippi' },
    { label: 'Missouri', value: '/locations/missouri' },
    { label: 'Montana', value: '/locations/montana' },
    { label: 'Nebraska', value: '/locations/nebraska' },
    { label: 'Nevada', value: '/locations/nevada' },
    { label: 'New Hampshire', value: '/locations/new-hampshire' },
    { label: 'New Jersey', value: '/locations/new-jersey' },
    { label: 'New Mexico', value: '/locations/new-mexico' },
    { label: 'New York', value: '/locations/new-york' },
    { label: 'North Carolina', value: '/locations/north-carolina' },
    { label: 'North Dakota', value: '/locations/north-dakota' },
    { label: 'Ohio', value: '/locations/ohio' },
    { label: 'Oklahoma', value: '/locations/oklahoma' },
    { label: 'Oregon', value: '/locations/oregon' },
    { label: 'Pennsylvania', value: '/locations/pennsylvania' },
    { label: 'Rhode Island', value: '/locations/rhode-island' },
    { label: 'South Carolina', value: '/locations/south-carolina' },
    { label: 'South Dakota', value: '/locations/south-dakota' },
    { label: 'Tennessee', value: '/locations/tennessee' },
    { label: 'Texas', value: '/locations/texas' },
    { label: 'Utah', value: '/locations/utah' },
    { label: 'Vermont', value: '/locations/vermont' },
    { label: 'Virginia', value: '/locations/virginia' },
    { label: 'Washington', value: '/locations/washington' },
    { label: 'West Virginia', value: '/locations/west-virginia' },
    { label: 'Wisconsin', value: '/locations/wisconsin' },
    { label: 'Wyoming', value: '/locations/wyoming' },
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title: 'Medicare Health Plans | Apply for Medicare',
          description: '',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/lp/bing-plans',
        promoCode: '88487',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Medicare Health Plans | Apply for Medicare',
    defaultRobots: 'follow,index',
    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero background-image-position-right"
              image={
                <img
                  src={coupleAtBeach}
                  alt="elderly couple walking on the beach and smiling"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1" color="dark">
                    Compare 2022 Aetna Medicare Plans{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>

        <SeoCtaBar />

        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">Click your state below</Typography>
              <Typography variant="h4">
                To get a COMPLIMENTARY Aetna Medicare quote on plans that are
                best for you.
              </Typography>
              <StateDropdown
                placeholderText="Choose Your Location"
                options={stateDropdownData}
                onChange={(e) => navigate(e.value)}
                className="state-dropdown"
                aoa={null}
              />
            </>
          }
        ></VariableContent>

        <SplitContent
          backgroundColor="lightGray"
          alignImageToBottom={false}
          image={
            <img
              src={manHoldingTablet}
              alt="an elderly man sits talks on a cell phone while holding and looking at a tablet"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">Get a FREE quote!</Typography>
              <Typography variant="body">
                Take the first step towards protecting your health with a
                personalized quote.{' '}
                <Link to={`tel:${rotatedNumber}`}>{rotatedNumber}</Link>.
              </Typography>
              <div className="card bg-light">
                <Typography variant="h4">
                  Request your complimentary Medicare insurance quote{' '}
                </Typography>
                <LinkButton
                  variant="feature"
                  to="/form"
                  color="primary"
                  className="margin-x-auto"
                >
                  Get Quote
                </LinkButton>
              </div>
            </>
          }
        />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
